<template>
    <div class="login-container login text-center animated flipInX">
      <div class="container-content">
        <transition name="slide-fade" appear>
          <form @submit.prevent="handleSubmit">
            <img src="@/assets/empresa.svg" alt="User" class="img-login">
            <h5 class="text-whitesmoke">PORTAL DE PROVEEDORES</h5>
            <p class="text-whitesmoke">Iniciar sesión</p>
            <div class="form-group" :class="{error: validation.hasError('email')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'envelope', }"/></span>
                </div>
                <input type="text" class="form-control" placeholder="Email" v-model="email">
                <span class="icon" :title="validation.firstError('email')" v-if="validation.hasError('email')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span>
              </div>
            </div>
            <div class="error-msg" v-if="validation.hasError('email')">{{ validation.firstError('email') }}</div>
            <div class="form-group" :class="{error: validation.hasError('password')}">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'lock', }"/></span>
                </div>
                <input v-if="showPassword" type="text" class="form-control" placeholder="Contraseña" v-model="password">
                <input v-else type="password" class="form-control" placeholder="Contraseña" v-model="password">
                <!-- <span class="icon" :title="validation.firstError('password')" v-if="validation.hasError('password')">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
                </span> -->
                <div class="input-group-append cursor-pointer">
                    <span class="input-group-text">
                        <div @click="toggleShow">
                            <!-- <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i> -->
                            <font-awesome-icon :icon="{ prefix: 'fa', iconName: showPassword ? 'eye-slash' : 'eye' }" class="no-help"/>
                        </div>
                    </span>
                </div>
              </div>
            </div>
            <div class="error-msg" v-if="validation.hasError('password')">{{ validation.firstError('password') }}</div>
            <button type="submit" class="form-button btn-block mb-2">
              <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>Entrar al Portal
            </button>

            <router-link class="text-gray" :to="'/auth/forgot-password'"><small>¿Olvidó su contraseña?</small></router-link><br><br/>
            <div><span class="text-whitesmoke text-center"><small>¿Aun no tiene una cuenta?</small></span></div>
            <router-link class="text-gray" :to="'/auth/register'"><small>REGISTRARSE COMO PROVEEDOR</small></router-link>
          </form>
        </transition>
      </div>
    </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'login',
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
  },
  data() {
    return {
      year: (new Date()).getFullYear(),
      email: '',
      password: '',
      linkingtk: undefined,
      showPassword: false,
    };
  },
  validators: {
    email(value) {
      return Validator.value(value).required('Campo requerido').email('Formato de correo no válido').lengthBetween(5, 100, 'Longitud debe ser entre 5 y 6 caracteres');
    },
    password(value) {
      return Validator.value(value).required('Campo requerido').lengthBetween(6, 100, 'Longitud minima de 6 caracteres');
    },
  },
  mounted() {
    if (this.$route.query.linked) {
      this.linkingtk = this.$route.query.linked;
      this.login();
    }
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    login() {
      this.$store.dispatch('user/LOGIN', { payload: { user: this.email, password: this.password, linkingtk: this.linkingtk } });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
